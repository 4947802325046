import React, { Component, useEffect } from "react";
import dynamic from "next/dynamic";
import Layout from "../component/layout/Layout";

import "../assets/scss/style.scss";

/** These need to be converted to modules: */
import "../form/formModal.css";
import "../component/playlistPlayer/PlaylistPlayer.css";
import "../form/analyticsModal/AnalyticsModal.css";
import "../page/BlankPage.css";
import "../component/breadCrumb/BreadCrumb.css";
import "../component/button/Button.css";
import "../component/button/ImageButton.css";
import "../component/screenRecorder/CameraRecorderStage.css";
import "../page/ChannelPage.css";
import "../component/grid/ChannelSearchGrid.css";

import "../component/loader/Checkmark.css";
import "../component/clipboardCopy/ClipboardCopy.css";
import "../component/comments/CommentList.css";
import "../component/textInput/quill.snow.css";
import "../component/shareButton/ContentShareButton.css";
import "../component/animations/Countdown.css";
import "../form/createLoginForm/CreateLoginForm.css";
import "../form/editAccountForm/EditAccountModal.css";
import "../form/editContentForm/EditContentForm.css";
import "../form/fileUploadForm/FileUploadForm.css";
import "../form/fileUploadForm/FileUploadItem.css";
import "../form/fileUploadForm/FileUploadList.css";
import "../component/filmstripItem/FilmstripItem.css";
import "../form/googleDriveUploadForm/GoogleDriveUploadModal.css";
import "../form/form.css";
import "../component/grid/GridItemTransition.css";
import "../component/homePageCards/HomePageCards.css";
import "../component/keywordCloud/KeywordCloud.css";
import "../component/loader/Loader.css";
import "../component/loader/LoaderSmall.css";
import "../form/manageShareUsersForm/ManageShareUsersModal.css";
import "../component/messageBar/MessageBar.css";
import "../component/pinInput/PinInput.css";
import "../component/player/Player.css";
import "../component/playlist/PlaylistItem.css";
import "../component/playlist/PlaylistList.css";
import "../component/playlistPlayer/PlaylistPlayer.css";
import "../form/quickDeleteForm/QuickDeleteDialog.css";
import "../form/quickEmbedForm/QuickEmbedDialog.css";
import "../form/quickEmbedForm/QuickEmbedForm.css";
import "../form/quickShareForm/QuickShareDialog.css";
import "../form/quickShareForm/QuickShareForm.css";
import "../component/animations/Recording.scss";
import "../component/animations/RecordingWave.css";
import "../component/chat/ChatTypingAnimation.css";
import "../component/drawer/RightDrawer.css";
import "../component/searchBar/SearchBar.css";
import "../component/keywordChipPanel/SegmentOccurrenceMenu.css";
import "../component/sidebar/Sidebar.css";
import "../component/textInput/TextInput.css";
import "../component/transcriptPanel/TranscriptItem.css";
import "../component/transcriptPanel/TranscriptPanel.css";
import "../form/updatePasswordForm/UpdatePasswordForm.css";
import "../component/sidebar/UserSearchSidebar.css";
import { configureStore } from "@reduxjs/toolkit";

const WebAppNoSSR = dynamic(() => import("../component/app/WebApp"), {
  ssr: false,
});

const NextApp = ({ Component, pageProps }) => {
  return (
    <Layout>
      <Component {...pageProps} />
      <WebAppNoSSR />
    </Layout>
  );
};

export default NextApp;
